.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // h1{
  // }
  .right-side {
    button {
      margin-left: 10px;
      font-size: 1rem;
      // font-weight: 500;
      letter-spacing: 2px;
      padding: 5px 10px;
      background-color: #791f28;
      color: white;
      border: none;
      border-radius: 4px;

      &.active {
        background-color: #c91f28;
        // color: rgba($color: #000000, $alpha: 0.75);
      }
      &:disabled {
        filter: contrast(0.2);
        opacity: 0.5;
      }
    }
  }
}
.table-layout {
  display: flex;
  flex-direction: column;
  overflow: auto;
  .table {
    display: flex;
    flex-direction: column;
    overflow: auto;
    .section-menu {
      display: flex;
      flex-direction: column;
      overflow: auto;
      .row {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex-wrap: wrap;
        gap: 10px;
        h2 {
          margin-bottom: 5px;
        }
        .column {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
          > div {
            flex-grow: 1;
          }
        }
        .item {
          flex: 0 1 0;
          // border: 1px solid rgba($color: #000000, $alpha: 0.2);
          padding: 5px 10px 16px;
          display: flex;
          gap: 10px;
          label {
            display: flex;
            // gap: 10px;
            align-items: center;
            width: fit-content;
            // flex-direction: column;
            width: 500px;
            justify-content: space-between;

            &.leading-dots {
              background-image: url("/assets/dot-svgrepo-com.svg");
              background-size: 18px;
              background-repeat: repeat-x;
              background-position-y: 18px;
            }
            > div {
              background-color: #ebebeb;
              position: relative;
            }
          }
          .label {
            padding-right: 5px;
          }
          input {
            // max-width: 100px;
            &[type="button"] {
              max-width: unset;
            }
          }
        }
      }
    }
  }
}
button:disabled,
input[type="button"]:disabled {
  filter: contrast(0.2);
  opacity: 0.5;
}
