.component {
  align-self: flex-start;
  display: flex;
  gap: 10px;
  // position: relative;
  width: 240px;
  padding-left: 5px;

  .input-wrap {
    position: relative;
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 37px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .submit-button {
    // display: none;
    // padding: 9px 12px;
    // background-color: #791f28;
    // border: none;
    // border-radius: 4px;
    // font-size: 1rem;
    // color: white;
    flex: 0 0 0;
    white-space: nowrap;
  }

  .equal-button {
    flex: 1 1 0;
  }

  input {
    padding: 8px 12px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #bbb;
    width: 0;
    min-width: calc(100% - 24px);
  }

  // input[type = text]{
  //     // background-color: red;
  // }
  input[type="button"] {
    background-color: #791f28;
    color: white;
    border: none;
    // height: 100%;
    width: 100%;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input[type="checkbox"] {
    transform: scale(1.4);
    min-width: 20px;
  }

  input.invalid {
    border: 1px solid rgb(255, 100, 100);
    box-shadow: 0 0 0 1px inset rgb(255, 100, 100);
    // background-color: rgb(255, 200, 200);
  }

  .errors {
    position: absolute;
    bottom: -1rem;
    .error {
      color: red;
      font-style: italic;
      font-size: 0.8rem;
      text-shadow: 0 0 6px #fff;
    }
  }

  .valid-input {
    height: 0.5rem;
    width: 0.5rem;
    padding: 6px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    img {
      // height: 100%;
      width: 12px;
      opacity: 0;
      filter: invert(1);
    }
    &.is-valid {
      background-color: rgb(40, 160, 40);
      // border-color: black;
      // border-color: rgb(0, 0, 0, 1);
      border-color: rgb(0, 0, 0, 0.15);
      img {
        opacity: 1;
      }
    }
  }
}
