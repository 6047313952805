.app {
  // font-family: Arial, Helvetica, sans-serif;
  background-color: #ebebeb;
  padding: 0 15px 0;
  user-select: none;
  height: 100vh;
  box-sizing: border-box;

  .sections-menu {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.this-is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}
.keyboard-wrap {
  // min-width: 700px;
  background-color: rgba($color: #555555, $alpha: 0.2);
  padding: 0px 5px 5px;
  opacity: 0.95;
  border-radius: 4px;
  width: fit-content;
  > .handle {
    cursor: grab;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4px;
    // background-color: rgba($color: #aaaaaa, $alpha: 0.2);
    > .line {
      border-bottom: 1px solid gray;
      margin-bottom: 4px;
      min-width: 200px;
    }
  }
  .react-simple-keyboard {
    min-width: 700px;
    &.hg-layout-numpad {
      min-width: 250px;
      margin: auto;
      .hg-rows {
        .hg-row {
          .hg-button {
            flex: 1 1 0;
          }
        }
      }
    }
    .red {
      background-color: rgb(255, 180, 180);
    }
    .enter-button {
      background-color: rgb(170, 235, 255);
      span {
        display: none;
      }
      &::after {
        content: "enter";
      }
    }
  }
}
